import Link from 'next/link'

import arrowImage from "@/app/assets/arrow.svg";
import Image from "next/image";


export default function ReturnHomeButton() {
    return (
        <Link
            className="inline-flex items-center py-4 px-20 bg-primary text-primary-foreground rounded-md hover:bg-primary/90 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            href="http://www.embarca.ai"
        >
            <Image
                src={arrowImage}
                className="w-4 h-4"
                alt={"Seta indicando o percurso"}
                height={16}
                width={16}
            />
            <span>Retornar a página inicial</span>
        </Link>
    )
}