"use client";
import FormSearchCityMobile from './FormSearchCityMobile';
import FormSearchCityDesktop from './FormSearchCityDesktop';
import ReturnHomeButton from '../buttons/ReturnHomeButton';
const banner = '/not_found_banner.webp';

export default function BannerNotFound() {
  return (
    <>
      {/* Form for mobile */}
      <section className="lg:hidden block bg-gray-5 rounded-b-3xl">
        <div className="pl-4 flex flex-col gap-1 md:ml-12">
          <h1 className="mt-4 text-[#E6FA00] text-5xl mb-3"><strong>404</strong></h1>
          <p className="text-[#E6FA00] text-3xl">Recalculando Rota...</p>
          <p className="text-white">Ops, não conseguimos encontrar a página que você procurava. Mas não se preocupe, você pode fazer uma nova busca ou acessar os links abaixo.</p>
        </div>
        <div className="flex items-center justify-center sm:m-4">
          <ReturnHomeButton />
        </div>
        <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
          {/* <FormSearchCityMobile /> */}
        </div>
      </section>
      {/* Form for desktop  */}
      <section style={{ '--image-url': `url(${banner})` } as React.CSSProperties}
        className={"hidden lg:flex bg-[image:var(--image-url)] bg-no-repeat bg-center bg-cover h-[22rem] mb-20"}>
        <div className="relative">
          <div className="absolute w-max top-1/4 max-w-6xl lg:ml-36 md:ml-18">
            <h1 className="mt-4 text-[#E6FA00] text-5xl mb-3"><strong>404</strong></h1>
            <p className="text-[#E6FA00] text-3xl">Recalculando Rota...</p>
            <p className="text-white">Ops, não conseguimos encontrar a página que você procurava. Mas não se preocupe, você pode fazer uma nova busca ou acessar os links abaixo.</p>
            {/* Como a aplicação está quebrando nesse import to form, coloquei esse botão pra não deixar o build quebrado */}
            <div className="flex items-center justify-center sm:m-4"><ReturnHomeButton /></div>
          </div>
        </div>
        {/* <FormSearchCityDesktop heightOption='higher' /> */}
      </section>
    </>
  )
}